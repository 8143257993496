<template>
  <b-modal
    id="insurance-edit-modal"
    title="Insurance Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Insurance Edit</h2>
    </template>
    <validation-observer ref="insuranceEditFormValidation">
      <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
          <template #label>
            Insurance Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Insurance Name"
            required
            @keydown="checkMaxLength($event, 'name', 200)"
          />
        </b-form-group>

        <b-form-group label-for="intro">
          <template #label> Intro <span class="text-danger">*</span> </template>
          <b-form-textarea
            id="intro"
            v-model="intro"
            placeholder="Intro"
            key="intro"
            required
            rows="3"
            max-rows="6"
            @keydown="checkMaxLength($event, 'intro', 200)"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label> Amount<span class="text-danger">*</span> </template>
          <b-form-input
            id="amount"
            v-model="amount"
            required
            placeholder="Amount"
            type="number"
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="image">
          <template #label> Image <span class="text-danger">*</span> </template>
          <div v-if="image" class="mb-2">
            <img
              :src="image"
              alt="Insurance Image"
              class="img-thumbnail"
              style="max-width: 15%; margin-bottom: 10px"
            />
          </div>
          <validation-provider v-slot="{ errors }" name="Image">
            <b-form-group
              class="form-group"
              id="input-group-2"
              label-for="input-2"
              description="Upload an image in PNG, JPG, or JPEG format."
              :invalid-feedback="errors[0]"
            >
              <b-form-file
                ref="imageInput"
                id="documents"
                placeholder="Choose a PNG, JPG, or JPEG file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".png,.jpg,.jpeg"
                plain
                v-model="files"
                @change="validateFileType($event, 'files')"
              ></b-form-file>
            </b-form-group>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormFile,
  },
  mixins: [util],
  props: {
    insurance: Object,
  },
  data() {
    return {
      name: "",
      intro: "",
      description: "",
      file: null,
      status: "",
      amount: "",
      image: null,
      files: [],
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.insurance) {
      this.name = this.insurance.name;
      this.description = this.insurance.description;
      this.intro = this.insurance.intro;
      this.status = this.insurance.status;
      this.amount = this.insurance.amount || "";
      this.image = this.insurance.display_image;
    }
  },
  methods: {
    ...mapActions({
      updateInsurance: "appData/updateInsurance",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    async submit() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("intro", this.intro);
      formData.append("description", this.description);
      formData.append("status", this.status);
      formData.append("amount", this.amount);
      if (this.files) {
        formData.append("display_image", this.files);
      }
      formData.append("updated_by", this.getLoggedInUser.id);

      try {
        const res = await this.updateInsurance({
          payload: formData,
          pk: this.insurance.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Insurance updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("insurance-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.intro = "";
      this.description = "";
      this.status = "";
      this.amount = "";
      this.file = null;
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },
};
</script>

<style></style>
