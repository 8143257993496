var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"insurance-create-modal","title":"Insurance Create Modal","centered":"","hide-footer":"","size":"lg","no-close-on-esc":true,"no-close-on-backdrop":true},on:{"hidden":_vm.reset},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("Insurance Create")])]},proxy:true}])},[_c('validation-observer',{ref:"InsuranceCreateFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(validate)}}},[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Insurance Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"restrict",rawName:"v-restrict",value:(_vm.regex),expression:"regex"}],attrs:{"id":"name","required":"","placeholder":"Insurance Name"},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'name', 200)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"intro"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Intro "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"intro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"intro","placeholder":"Introduction","required":"","rows":"3","max-rows":"6"},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'intro', 200)}},model:{value:(_vm.intro),callback:function ($$v) {_vm.intro=$$v},expression:"intro"}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"amount"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Amount"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"amount","placeholder":"Amount","type":"number","min":"1","step":"1","required":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),(!_vm.amountState)?_c('b-form-invalid-feedback',[_vm._v(" Please enter a valid positive integer. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Status "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status","options":_vm.options,"required":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"form-group",attrs:{"id":"input-group-2","label-for":"input-2","description":"Upload an image in PNG, JPG, or JPEG format.","invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{ref:"imageInput",attrs:{"id":"documents","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","required":"","plain":"","accept":".png,.jpg,.jpeg"},on:{"change":function($event){return _vm.validateFileType($event)}},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)]}}],null,true)}),_c('b-form-group',{staticClass:"mt-2 text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","pill":""}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary","pill":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }