<template>
  <b-modal
    id="insurance-create-modal"
    title="Insurance Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Insurance Create</h2>
    </template>
    <validation-observer
      ref="InsuranceCreateFormValidation"
      v-slot="{ validate }"
    >
      <b-form @submit.prevent="submit(validate)">
        <b-form-group label-for="name">
          <template #label>
            Insurance Name <span class="text-danger">*</span>
          </template>
          <validation-provider name="name" rules="required" v-slot="{ errors }">
            <b-form-input
              id="name"
              v-model="name"
              v-restrict="regex"
              required
              placeholder="Insurance Name"
              @keydown="checkMaxLength($event, 'name', 200)"
            />
            <p class="text-danger">{{ errors[0] }}</p>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="intro">
          <template #label> Intro <span class="text-danger">*</span> </template>
          <validation-provider
            name="intro"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-textarea
              id="intro"
              v-model="intro"
              placeholder="Introduction"
              required
              rows="3"
              max-rows="6"
              @keydown="checkMaxLength($event, 'intro', 200)"
            ></b-form-textarea>
            <p class="text-danger">{{ errors[0] }}</p>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <validation-provider name="description" v-slot="{ errors }">
            <quill-editor v-model="description" />
            <p class="text-danger">{{ errors[0] }}</p>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label> Amount<span class="text-danger">*</span> </template>
          <b-form-input
            id="amount"
            v-model="amount"
            placeholder="Amount"
            type="number"
            min="1"
            step="1"
            required
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <validation-provider
            name="status"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-select
              id="status"
              v-model="status"
              :options="options"
              required
            ></b-form-select>
            <p class="text-danger">{{ errors[0] }}</p>
          </validation-provider>
        </b-form-group>

        <validation-provider v-slot="{ errors }" name="Image" rules="required">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
            <template #label>
              Image <span class="text-danger">*</span>
            </template>
            <b-form-file
              ref="imageInput"
              id="documents"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              required
              plain
              accept=".png,.jpg,.jpeg"
              v-model="files"
              @change="validateFileType($event)"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            pill
            @click="reset"
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormFile,
  },
  mixins: [util],
  data() {
    return {
      required,
      name: "",
      intro: "",
      description: "",
      status: "",
      amount: "",
      files: [],
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,

      files: null,
      selectedImageNames: [],
    };
  },
  methods: {
    ...mapActions({
      createInsurance: "appData/createInsurance",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    async submit(validate) {
      const isValid = await validate();
      if (!isValid) {
        this.$swal({
          title: "Validation Error",
          text: "Please fill in all required fields correctly.",
          icon: "error",
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("intro", this.intro);
        formData.append("description", this.description);
        formData.append("status", this.status);
        formData.append("amount", this.amount);
        formData.append("created_by", this.getLoggedInUser.id);
        formData.append("updated_by", this.getLoggedInUser.id);

        if (this.files) {
          formData.append("display_image", this.files);
        }

        const res = await this.createInsurance(formData);
        if (res.status === 200) {
          this.$swal({
            title: "Insurance is created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("insurance-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.intro = "";
      this.description = "";
      this.status = "";
      this.amount = "";
      this.files = null;
      this.selectedImageNames = [];
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },
};
</script>
