<template>
    <div>
      <div class="text-right mb-1">
        <b-button
          variant="primary"
          pill
          @click="createInsurance"
          v-if="hasPermission('create_insurance')"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Create</span>
        </b-button>
      </div>
      <b-card>
        <!-- Filters Below  -->
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Name"
              />
            </b-form-group>
            
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- Filters above  -->
        <b-table
          responsive="sm"
          :fields="fields"
          :items="insurances"
          details-td-class="m-0 p-0"
          small
          v-if="hasPermission('create_insurance')"
          :per-page="0"
        >
        <template #cell(serial)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #cell(name)="row">
        {{ truncate(row.item.name, 20) }} 
      </template>
      <template #cell(intro)="row">
        {{ truncate(row.item.intro, 20) }} 
      </template>
        <template #cell(amount)="row">
            {{ row.item.amount }}
          </template>
          <template #cell(created_at)="row">
            {{ row.item.created_at }}
          </template>
          <template #cell(updated_at)="row">
            {{ row.item.updated_at }}
          </template>
          <template #cell(created_by_data)="row">
            {{ row.item.created_by}}
          </template>
          <template #cell(updated_by_data)="row">
            {{ row.item.updated_by }}
          </template>
          
          <template #cell(manage)="row">
            <b-button
              variant="info"
              pill
              size="sm"
              class="mr-1"
              @click="editInsurance(row.item)"
              v-if="hasPermission('update_insurance')"
            >
              Edit
            </b-button>
            <b-button
              variant="danger"
              pill
              size="sm"
              @click="removeInsurance(row.item)"
              v-if="hasPermission('delete_insurance')"
            >
              Delete
            </b-button>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </b-card>
      <InsuranceCreateModal
        @modalClosed="onModalClosed"
        :key="`create-${insuranceCreateModalCount}`"
      />
      <InsuranceEditModal
        :insurance="insurance"
        @modalClosed="onModalClosed"
        :key="`edit-${insuranceEditModalCount}`"
      />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import InsuranceCreateModal from "@/components/admin/insurance/InsuranceCreateModal.vue";
import InsuranceEditModal from "@/components/admin/insurance/InsuranceEditModal.vue";

export default {
  components: {
    InsuranceCreateModal,
    InsuranceEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "intro", label: "Intro" },
        { key: "amount", label: "Amount" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Updated At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      insurances: [],
      insurance: null,
      insuranceCreateModalCount: 0,
      insuranceEditModalCount: 0,
      searchTypes: [
        { value: 1, name: "Name" },
      ],
      searchType: null,
      name: "",
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getInsurance: "appData/getInsurance",
      deleteInsurance: "appData/deleteInsurance",
    }),
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            break;
        }
      } else {
        this.name = "";
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchPaginatedData() {
      try {
        const res = await this.getInsurance({
          pageNumber: this.currentPage,
          name: this.name ? this.name : "",
        });
        this.insurances = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createInsurance() {
      this.insuranceCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("insurance-create-modal");
      });
    },
    editInsurance(insurance) {
      this.insurance = insurance;
      this.insuranceEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("insurance-edit-modal");
      });
    },
    async removeInsurance(insurance) {
      this.insurance = insurance;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteInsurance({
              pk: this.insurance.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Insurance deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
